/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { type ReactNode, useMemo, useRef, forwardRef } from "react";

import { cssFns } from "@superweb/css";

import { useUiColors } from "../theme";
import { useTypo } from "../typo";
import { useIsMobile } from "../mobile-context";
import { BottomSheetContextProvider } from "../bottom-sheet";
import { LayoutContextProvider } from "./layout-container";

const navMenuWidth = 64;
export const headerDesktopHeight = 60;
const headerMobileHeight = 56;
export const mainPaddings = 16;
export const mainPaddingBlockStart = 8;

export const AppLayout = ({
  menu,
  content,
  overflowContentInMobile = false,
}: {
  menu?: ReactNode;
  content: ReactNode;
  overflowContentInMobile?: boolean;
}) => {
  const isMobile = useIsMobile();
  const fullHeight = CSS.supports("height: 100dvh") ? "100dvh" : "100vh";

  const layoutContainerRef = useRef<HTMLDivElement>(null);

  return (
    <LayoutContextProvider
      value={{
        container: layoutContainerRef,
      }}
    >
      <div
        ref={layoutContainerRef}
        css={{
          display: "grid",
          gridTemplateColumns:
            isMobile || !menu ? "1fr" : `${navMenuWidth}px 1fr`,
          gridTemplateRows: `minmax(min-content, ${fullHeight}) 1fr`,
          minHeight: fullHeight,
          isolation: "isolate",
          ...(overflowContentInMobile && isMobile
            ? {
                maxHeight: fullHeight,
                maxWidth: "100vw",
                overflowX: "hidden",
              }
            : {
                minWidth: "min-content",
              }),
        }}
      >
        {!isMobile && menu && (
          <div
            css={{
              position: "sticky",
              height: fullHeight,
              insetBlockStart: "0",
              insetInlineStart: "0",
              zIndex: "2",
              display: "grid",
              ...cssFns.gridArea("1"),
            }}
          >
            {menu}
          </div>
        )}
        <div
          css={{
            ...(overflowContentInMobile && isMobile && cssFns.overflow("auto")),
          }}
        >
          {content}
        </div>
      </div>
    </LayoutContextProvider>
  );
};

export const PageLayout = ({
  title,
  burgerMenuButton,
  toolbar,
  userToolbar,
  content,
  stickyHeader = false,
  disableContentMargins = false,
}: {
  title: ReactNode;
  burgerMenuButton: ReactNode;
  toolbar?: ReactNode;
  userToolbar?: ReactNode;
  content: ReactNode;
  stickyHeader?: boolean;
  disableContentMargins?: boolean;
}) => {
  const isMobile = useIsMobile();
  const fullHeight = CSS.supports("height: 100dvh") ? "100dvh" : "100vh";

  const bottomSheetContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <BottomSheetContextProvider value={bottomSheetContainerRef}>
        <Header stickyHeader={stickyHeader}>
          <div css={{ display: "flex", alignItems: "center", flexGrow: "1" }}>
            {isMobile && (
              <div
                css={{
                  width: "56px",
                  height: `${headerMobileHeight}px`,
                  display: "grid",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                {burgerMenuButton}
              </div>
            )}

            <Title>{title}</Title>
            <div
              css={{ display: "flex", flexGrow: "1", marginInlineEnd: "8px" }}
            >
              {toolbar}
            </div>
          </div>
          {userToolbar && <div>{userToolbar}</div>}
        </Header>

        <main
          css={{
            display: "grid",
            minHeight: `calc(${fullHeight} - ${
              isMobile ? headerMobileHeight : headerDesktopHeight
            }px)`,
            boxSizing: "border-box",
            ...(!isMobile &&
              !disableContentMargins && {
                ...cssFns.padding(`${mainPaddings}px`),
                paddingBlockStart: `${mainPaddingBlockStart}px`,
              }),
          }}
        >
          {content}
        </main>
        <BottomSheetPortal ref={bottomSheetContainerRef} />
      </BottomSheetContextProvider>
    </>
  );
};

const Header = ({
  stickyHeader,
  children,
}: {
  stickyHeader: boolean;
  children: ReactNode;
}) => {
  const uiColors = useUiColors();

  const scrollWidth = useMemo(() => {
    const div = document.createElement("div");

    div.style.overflowY = "scroll";
    div.style.width = "50px";
    div.style.height = "50px";

    // мы должны вставить элемент в документ, иначе размеры будут равны 0
    document.body.append(div);
    const scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();

    return scrollWidth;
  }, []);

  const isMobile = useIsMobile();

  return (
    <header
      css={{
        position: "sticky",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: uiColors.backgroundMinor,
        boxSizing: "border-box",
        ...(isMobile
          ? {
              width: `calc(100vw - ${scrollWidth}px)`,
              height: `${headerMobileHeight}px`,
              insetInlineStart: "0px",
              gridTemplateColumns: "min-content auto",
              paddingInlineEnd: "12px",
            }
          : {
              width: `calc(100vw - ${navMenuWidth}px - ${scrollWidth}px)`,
              height: `${headerDesktopHeight}px`,
              insetInlineStart: `${navMenuWidth}px`,
              gridTemplateColumns: "auto",

              paddingInlineStart: "16px",
              paddingInlineEnd: "12px",
              paddingBlockStart: "12px",
              paddingBlockEnd: "8px",
            }),
        ...(stickyHeader && {
          top: "0",
          zIndex: "1",
        }),
      }}
    >
      {children}
    </header>
  );
};

const Title = ({ children }: { children: ReactNode }) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const isMobile = useIsMobile();

  return (
    <h1
      css={{
        ...cssFns.margin("0"),
        marginInlineEnd: "16px",
        color: uiColors.text,
        ...(isMobile
          ? typo({
              level: "body1",
              weight: "medium",
              density: "tight",
            })
          : typo({
              level: "title3",
              weight: "medium",
              density: "tight",
            })),
      }}
    >
      {children}
    </h1>
  );
};

const BottomSheetPortal = forwardRef<HTMLDivElement, {}>(({}, ref) => {
  const isMobile = useIsMobile();

  const scrollWidth = useMemo(() => {
    const div = document.createElement("div");

    div.style.overflowY = "scroll";
    div.style.width = "50px";
    div.style.height = "50px";

    // мы должны вставить элемент в документ, иначе размеры будут равны 0
    document.body.append(div);
    const scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();

    return scrollWidth;
  }, []);

  return (
    <div
      ref={ref}
      css={{
        position: "sticky",
        bottom: "0",
        boxSizing: "border-box",
        ...(isMobile
          ? {
              width: "100vw",
              insetInlineStart: "0",
            }
          : {
              width: `calc(100vw - ${navMenuWidth}px - ${scrollWidth}px)`,
              insetInlineStart: `${navMenuWidth}px`,
            }),
      }}
    />
  );
});

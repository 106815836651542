/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns, type Style } from "@superweb/css";
import {
  createContext,
  useContext,
  useEffect,
  useId,
  type MutableRefObject,
} from "react";
import { createPortal } from "react-dom";
import { useUiColors, useUiShadows } from "./theme";
import { icons } from "./icons";
import { useTypo } from "./typo";

const DEFAULT_TIMEOUT = 3000;

export type SnackbarProps = {
  /**
   * The text displayed in the body of snackbar.
   */
  text: string;

  /**
   * The number of milliseconds to wait before automatically calling the onClose function.
   * @defaultValue `3000`
   */
  timeout?: number;

  /**
   * Visual variant of snackbar.
   */
  view?: "critical";

  /**
   * The text is displayed on the action button. The button is displayed if the text is set.
   */
  action?: string;

  /**
   * Callback fired when mouse, touch or keyboard event occur on close button.
   */
  onClose: () => void;

  /**
   * Callback fired when mouse, touch or keyboard event occur on action button.
   */
  onAction?: () => void;
};

const SnackbarContext =
  createContext<MutableRefObject<HTMLDivElement | null> | null>(null);

export const SnackbarContextProvider = SnackbarContext.Provider;

export const useSnackbarContext = () => useContext(SnackbarContext);

export const Snackbar = ({
  timeout = DEFAULT_TIMEOUT,
  view,
  text,
  action,
  onClose,
  onAction,
}: SnackbarProps) => {
  const typo = useTypo();
  const id = useId();

  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const ref = useSnackbarContext();

  const buttonStyle: Style = {
    ...cssFns.padding("0"),
    ...cssFns.margin("0"),
    ...cssFns.border({ width: "0" }),
    cursor: "pointer",
    justifyContent: "center",
    flexShrink: "0",
    display: "flex",
    marginInlineStart: "8px",
    backgroundColor: "transparent",
    color: cssFns.setOpacity(
      view === "critical" ? uiColors.text : uiColors.textInvert,
      0.5,
    ),
  };

  useEffect(() => {
    const t = setTimeout(() => onClose(), timeout);
    return () => clearTimeout(t);
  }, [timeout, onClose]);

  return ref?.current
    ? createPortal(
        <div
          role="alertdialog"
          aria-modal="false"
          aria-labelledby={id}
          css={{
            marginBlockEnd: "10px",
            pointerEvents: "auto",
            color:
              view === "critical" ? uiColors.everFront : uiColors.textInvert,
            display: "flex",
            justifyContent: "space-between",
            boxSizing: "border-box",
            alignContent: "center",
            alignItems: "center",
            maxWidth: "100%",
            boxShadow: uiShadows.bottomNormal,
            backgroundColor:
              view === "critical"
                ? uiColors.alerts.error
                : uiColors.backgroundInvert,
            ...cssFns.border({ radius: "16px" }),
            ...cssFns.padding("10px", "12px"),
            ...cssFns.overflow("hidden"),
          }}
        >
          <span
            id={id}
            css={{
              ...typo({
                level: "body2",
                density: "normal",
                weight: "regular",
              }),
            }}
          >
            {text}
          </span>

          {action && (
            <button css={buttonStyle} onClick={onAction}>
              {action}
            </button>
          )}

          <button css={buttonStyle} onClick={onClose}>
            <icons.Cross />
          </button>
        </div>,
        ref.current,
      )
    : null;
};

import { useCallback, useState } from "react";
import type { OverlayTriggerState } from "react-stately";

export type NavMenuState = {
  openedSubmenuIndex?: number;
  submenuTriggerType: "click" | "hover";

  openSubMenu: (index: number) => void;
  closeSubMenu: () => void;
};

export type MobileMenuState = NavMenuState & {
  popoverState: OverlayTriggerState;
};

export const useNavMenuState = ({
  submenuTriggerType = "click",
}: {
  submenuTriggerType?: "click" | "hover";
}): NavMenuState => {
  const [openedSubmenuIndex, setOpenedSubmenuIndex] = useState<
    number | undefined
  >();

  const openSubMenu = useCallback(
    (index: number) => {
      setOpenedSubmenuIndex(index);
    },
    [setOpenedSubmenuIndex],
  );

  const closeSubMenu = useCallback(() => {
    setOpenedSubmenuIndex(undefined);
  }, [setOpenedSubmenuIndex]);

  return {
    openedSubmenuIndex,
    submenuTriggerType,
    openSubMenu,
    closeSubMenu,
  };
};

export const useMobileMenuState = (
  state: NavMenuState,
  popoverState: OverlayTriggerState,
): MobileMenuState => {
  return {
    ...state,
    popoverState,
  };
};

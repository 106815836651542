import type { Location } from "history";
import { matchPath } from "react-router-dom";

export const referralAltCheckLink = (refId: string) =>
  `/ref_alt/${refId}/complete`;

export const referralAltLink = ({
  id,
  locale,
}: {
  id: string;
  locale: string;
}) => `/ref_alt/${id}?locale=${locale}`;

export const referralAltChecklLinkParams = (location: Location) => {
  const match = matchPath("/ref_alt/:refId/complete", location.pathname);

  return {
    referralId: match?.params.refId,
  };
};

export const referralAltLinkParams = (location: Location) => {
  const match = matchPath("/ref_alt/:refId", location.pathname);

  return {
    referralId: match?.params.refId,
  };
};

export const getReferralIdFromLocation = (location: Location) => {
  let match = matchPath("/ref_alt/:refId/complete", location.pathname);
  if (!match) {
    match = matchPath("/ref_alt/:refId", location.pathname);
  }

  return {
    referralId: match?.params.refId,
  };
};

import React from "react";
import { Css } from "../css";

/**
 * @internal
 * @deprecated Do not use directly.
 * This function is only exported as a fallback
 * for automatic JSX runtime transform.
 * {@link https://legacy.reactjs.org/blog/2020/09/22/introducing-the-new-jsx-transform.html}
 */
export const createElement: unknown = (
  type: any,
  {
    css,
    __experimental_placeholderCss,
    __experimental_webkitScrollbarCss,
    key,
    ...props
  }: any,
  ...children: any
) => {
  if (
    typeof type !== "string" ||
    !props ||
    !(
      props.css ||
      props.__experimental_placeholderCss ||
      props.__experimental_webkitScrollbarCss
    )
  ) {
    return React.createElement(type, { ...props, key }, ...children);
  }
  return React.createElement(
    Css,
    {
      css,
      __experimental_placeholderCss,
      __experimental_webkitScrollbarCss,
      key,
      type,
      props,
    },
    ...children,
  );
};

import { createThemeColorsContext } from "./colors-context";

export type UiColorsValue = {
  /**
   * Background color for components like widgets, tables, forms and etc.
   */
  background: string;
  /**
   * Background color for main pages, menu and etc.
   */
  backgroundMinor: string;
  /**
   * Background color for bottom sheets and for floating buttons.
   */
  backgroundFloating: string;
  /**
   * If you need to use colors from inverted theme, use this color. In particular, for guidance.
   */
  backgroundInvert: string;
  /**
   * Main color for action components like action buttons, checkboxes ticks fields and etc.
   */
  control: string;
  /**
   * For components like form fields, minor buttons and etc.
   */
  controlMinor: string;
  /**
   * For main text.
   */
  text: string;
  /**
   * For minor text.
   */
  textMinor: string;
  /**
   * If you need to use colors from inverted theme, use this color. In particular, for guidance.
   */
  textInvert: string;
  /**
   * For text that lies on control color.
   */
  textOnControlMain: string;
  /**
   * Mostly, we use alerts.error color, but sometimes we need this color. For example, for errors in text.
   */
  error: string;
  /**
   * For lines. For example, in table cell separator.
   */
  line: string;
  /**
   * It is used to 'hide' background when dialog or menu is open.
   */
  fog: string;
  /**
   * For elements that are ALWAYS black.
   */
  everBack: string;
  /**
   * For elements that are ALWAYS white.
   */
  everFront: string;
  /**
   * Color for separating blocks.
   */
  cardDivider: string;

  /**
   * For hover state. Use blend function or additional div with this color as a background color.
   */
  hover: string;
  /**
   * For press state. Use blend function or additional div with this color as a background color.
   */
  press: string;
  /**
   * For focus state. It is usually used as a border color.
   */
  focus: string;

  /**
   * @deprecated use `uiColors.statuses`
   */
  alerts: {
    /**
     * For info alerts, that just convey some information about part of the site.
     */
    info: string;
    /**
     * For success alerts, messages and sometimes successes in progress.
     */
    success: string;
    /**
     * For warning alerts, that warn user that something is going to go wrong soon.
     */
    warning: string;
    /**
     * For error alerts, that report that something is going wrong.
     */
    error: string;
  };

  /**
   * For default charts and line graphs.
   */
  categorical: {
    category1: string;
    category2: string;
    category3: string;
    category4: string;
    category5: string;
    category6: string;
    category7: string;
    category8: string;
    category9: string;
  };

  /**
   * For trend charts.
   */
  diverging: {
    positive100: string;
    positive80: string;
    positive60: string;
    positive40: string;
    positive20: string;
    neutral: string;
    negative20: string;
    negative40: string;
    negative60: string;
    negative80: string;
    negative100: string;
  };

  /**
   * For chart's elements.
   */
  charts: {
    /**
     * For marking up the chart.
     */
    axes: string;
    /**
     * For text near guide axes.
     */
    numbers: string;
    /**
     * For chart lines and legend that represent past period.
     */
    pastPeriod: string;
  };

  statuses: {
    primary: string;
    secondary: string;
    success: string;
    warning: string;
    danger: string;
  };
};

const [UiColors, useUiColors] =
  createThemeColorsContext<UiColorsValue>("uiColors");

export { UiColors, useUiColors };

import {
  useInfiniteQuery as useBaseInfiniteQuery,
  type UseInfiniteQueryResult as UseBaseInfiniteQueryResult,
} from "@tanstack/react-query";

export type InfiniteData<D = unknown> = {
  pages: D[];
  pageParams: unknown[];
};

export type UseInfiniteQueryOptions<D = unknown, E = unknown> = {
  cacheTime?: number;
  enabled?: boolean;
  getNextPageParam?: (lastPage: D, allPages: D[]) => unknown;
  getPreviousPageParam?: (firstPage: D, allPages: D[]) => unknown;
  keepPreviousData?: boolean;
  onError?: (error: E) => void;
  onSuccess?: (data: InfiniteData<D>) => void;
  refetchInterval?: number | false;
  refetchIntervalInBackground?: boolean;
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
  retry?: boolean | number | ((failureCount: number, error: E) => boolean);
  staleTime?: number;
  suspense?: boolean;
  useErrorBoundary?: boolean;
};

export type UseInfiniteQueryResult<D = unknown, E = unknown> = (
  | {
      data: undefined;
      error: E;
      isError: true;
      isIdle: false;
      isLoading: false;
      isSuccess: false;
      status: "error";
    }
  | {
      data: undefined;
      error: null;
      isError: false;
      isIdle: true;
      isLoading: false;
      isSuccess: false;
      status: "idle";
    }
  | {
      data: undefined;
      error: null;
      isError: false;
      isIdle: false;
      isLoading: true;
      isSuccess: false;
      status: "loading";
    }
  | {
      data: InfiniteData<D>;
      error: null;
      isError: false;
      isIdle: false;
      isLoading: false;
      isSuccess: true;
      status: "success";
    }
) & {
  fetchNextPage: (params?: { pageParam?: unknown }) => Promise<unknown>;
  fetchPreviousPage: (params?: { pageParam?: unknown }) => Promise<unknown>;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  isFetchingPreviousPage: boolean;
  refetch: () => Promise<void>;
  remove: () => void;
};

const refetchMap = new WeakMap();
const removeMap = new WeakMap();

const toUseInfiniteQueryResult = <D, E>({
  data,
  error,
  status,
  fetchStatus,
  fetchNextPage,
  fetchPreviousPage,
  hasNextPage,
  hasPreviousPage,
  isFetching,
  isFetchingNextPage,
  isFetchingPreviousPage,
  refetch,
  remove,
}: UseBaseInfiniteQueryResult<D, E>): UseInfiniteQueryResult<D, E> => {
  const rest = {
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    refetch:
      refetchMap.get(refetch) ??
      refetchMap
        .set(refetch, async () => {
          await refetch();
        })
        .get(refetch),
    remove:
      removeMap.get(remove) ??
      removeMap
        .set(remove, () => {
          remove();
        })
        .get(remove),
  };
  switch (status) {
    case "error": {
      return {
        data: undefined,
        error,
        isError: true,
        isIdle: false,
        isLoading: false,
        isSuccess: false,
        status: "error",
        ...rest,
      };
    }
    case "loading": {
      return fetchStatus === "idle"
        ? {
            data: undefined,
            error: null,
            isError: false,
            isIdle: true,
            isLoading: false,
            isSuccess: false,
            status: "idle",
            ...rest,
          }
        : {
            data: undefined,
            error: null,
            isError: false,
            isIdle: false,
            isLoading: true,
            isSuccess: false,
            status: "loading",
            ...rest,
          };
    }
    case "success": {
      return {
        data,
        error: null,
        isError: false,
        isIdle: false,
        isLoading: false,
        isSuccess: true,
        status: "success",
        ...rest,
      };
    }
  }
};

export const useInfiniteQuery = <D, E>(
  key: readonly unknown[],
  fn: (params: { pageParam?: any }) => D | Promise<D>,
  options?: UseInfiniteQueryOptions<D, E>,
): UseInfiniteQueryResult<D, E> => {
  return toUseInfiniteQueryResult(useBaseInfiniteQuery<D, E>(key, fn, options));
};

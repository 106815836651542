import { useContext } from "react";

import { OnboardingContext } from "./onboarding";

export const useOnboarding = () => useContext(OnboardingContext);

export const useCurrentStepIndex = () => useOnboarding().currentStepIndex;

export const useSetCurrentStepIndex = () => {
  const setCurrentStepIndex = useOnboarding().setCurrentStepIndex;

  return (value: number | null) => setCurrentStepIndex(value);
};

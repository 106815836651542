/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
  type ComponentType,
  type PointerEvent,
  type ReactNode,
} from "react";
import { useFocusRing, useId } from "react-aria";
import useResizeObserver from "use-resize-observer";

import { useLocale } from "@superweb/intl";
import { cssFns } from "@superweb/css";

import { Message, Messages, useMessage } from "#intl";

import { Button } from "./buttons/button";
import { useUiColors } from "./theme";
import { useTypo } from "./typo";
import { icons } from "./icons";
import { useIsMobile } from "./mobile-context";
import { InformationPopover } from "./information-popover/information-popover";

type Size = "s" | "m" | "l";
type TotalCardStatus = "error" | "warning" | "default";

const maxItemsArrayLength = 6;
const rowGap = 16;
const minTotalWidth = 312;
const wideScreenMinWidth = 1330;

/**
 * Total can be one of three variants:
 *  - "list": list of items with label and value, up to 6 items
 *  - "double-values": two values with optional captions
 *  - "single-value": single value with optional caption
 */
export type Total = {
  /**
   * Total's label placed in the header.
   */
  label: string;

  /**
   * Total's description placed in the header.
   */
  description?: string;

  /**
   * Details of the link placed in the header.
   */
  link?: {
    text: string;
    href: string;
    onClick?: (e: PointerEvent<HTMLAnchorElement>) => void;
  };

  /**
   * Content of information popover placed in the header.
   * Some hint about what information is displayed in total card.
   */
  informationPopover?: ReactNode;
} & (
  | {
      variant: "list";

      /**
       * Recommended max length of array: 6.
       */
      list: {
        value: string;
        label: string;
      }[];
    }
  | {
      variant: "double-values";

      valueInfo?: [TotalValueInfo, TotalValueInfo];

      action?: TotalButton;

      /**
       * Total card's status. It changes appearance of element.
       * For cards similar to wigdets.
       * @defaultValue "default"
       */
      status?: TotalCardStatus;
    }
  | {
      variant: "single-value";

      valueInfo?: TotalValueInfo;

      action?: TotalButton;

      /**
       * Total card's status. It changes appearance of element.
       * For cards similar to wigdets.
       * @defaultValue "default"
       */
      status?: TotalCardStatus;
    }
);

type TotalValueInfo = {
  value: string;
  caption?: string;
};

type TotalButton = {
  /**
   * Visible text.
   * Also gives the button an accessible name.
   *
   * Should describe the action performed by the button.
   *
   * That is:
   * - either describe the action directly with a verb
   *   (e.g. "Submit", "Delete");
   * - or refer to an action mentioned above (e.g. "OK", "Confirm").
   *
   * Avoid using ambiguous texts such as "Yes/No" which may be interpreted
   * differently by people depending on their perception or cultural context.
   */
  text: string;

  /**
   * Callback fired when mouse, touch or keyboard event occur on the button.
   */
  onPress?: () => void;

  /**
   * Text of tooltip, that is displayed by hover and focus.
   */
  tooltip?: string;

  /**
   * If `true`, button is disabled.
   */
  disabled?: boolean;
};

const Header = ({
  title,
  description,
  descriptionId,
  link,
  status = "default",
  informationPopover,
}: {
  title: string;
  description?: string;
  descriptionId?: string;
  link?: {
    text: string;
    href: string;
    onClick?: (e: PointerEvent<HTMLAnchorElement>) => void;
  };
  status?: TotalCardStatus;
  informationPopover?: ReactNode;
}) => {
  const typo = useTypo();
  const message = useMessage();
  const uiColors = useUiColors();

  const {
    textInfo: { direction },
  } = useLocale();

  const { focusProps, isFocusVisible: isFocused } = useFocusRing();

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "start",
        columnGap: "16px",
      }}
    >
      <div css={{ display: "flex", flexDirection: "column", rowGap: "2px" }}>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            columnGap: "2px",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <span
            css={{
              ...typo({ level: "body1", density: "tight", weight: "medium" }),
              color: status !== "default" ? uiColors.everFront : uiColors.text,
            }}
          >
            {title}
          </span>
          {informationPopover && (
            <div css={{ alignSelf: "start" }}>
              <InformationPopover
                variant="help"
                ariaLabel={message({
                  id: "e4edb894-6bff-41f7-90f2-3746d9f9222c",
                  context: "Totals. Total's header. Information popover button",
                  default: "Help button",
                })}
              >
                {informationPopover}
              </InformationPopover>
            </div>
          )}
        </div>
        <span
          css={{
            ...typo({
              level: "caption1",
              density: "tight",
              weight: "regular",
            }),
            color:
              status !== "default" ? uiColors.everFront : uiColors.textMinor,
          }}
          id={descriptionId}
        >
          {description}
        </span>
      </div>
      {link && (
        <a
          {...focusProps}
          href={link.href}
          onClick={(e: PointerEvent<HTMLAnchorElement>) => {
            link.onClick?.(e);
          }}
          css={{
            display: "flex",
            alignItems: "center",
            outlineStyle: "none",
            cursor: "pointer",
            textDecorationLine: "none",
            backgroundColor: "transparent",
            color: status !== "default" ? uiColors.everFront : uiColors.text,
            ...cssFns.border({ radius: "13px", style: "none" }),
            ...(isFocused &&
              cssFns.boxShadow({
                inset: true,
                spreadRadius: "2px",
                color: uiColors.focus,
              })),
            ...cssFns.margin("-2px"),
            ...cssFns.padding("2px"),
            ...(link.text && {
              marginInlineStart: "-10px",
              paddingInlineStart: "10px",
            }),
          }}
        >
          {link.text && (
            <div
              css={{
                ...typo({ level: "body2", density: "tight", weight: "medium" }),
              }}
            >
              {link.text}
            </div>
          )}
          {direction === "rtl" ? <icons.CornerLeft /> : <icons.CornerRight />}
        </a>
      )}
    </div>
  );
};

const List = ({
  items,
}: {
  items: {
    value: string;
    label: string;
  }[];
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        rowGap: "4px",
        justifyContent: "end",
        ...cssFns.overflow("hidden"),
      }}
    >
      {items.map((item, index) => {
        if (
          index < maxItemsArrayLength - 1 ||
          (items.length === maxItemsArrayLength &&
            index === maxItemsArrayLength - 1)
        ) {
          return (
            <div
              key={index}
              css={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                ...cssFns.overflow("hidden"),
                color: uiColors.textMinor,
              }}
            >
              <span
                css={{
                  ...typo({
                    level: "body2",
                    weight: "regular",
                    density: "tight",
                  }),
                  color: uiColors.text,
                }}
              >
                {item.value}
              </span>
              <span
                css={{
                  ...typo({
                    level: "caption1",
                    weight: "regular",
                    density: "tight",
                  }),
                  color: uiColors.textMinor,
                }}
              >
                <Message
                  id="6d8df31a-812d-4bf4-be27-9bdcbd55c77f"
                  context="Totals. Total's list. List's item content"
                  default=" · {value}"
                  values={{ value: item.label }}
                />
              </span>
            </div>
          );
        } else if (index === maxItemsArrayLength - 1) {
          return (
            <div
              key={index}
              css={{
                ...typo({
                  level: "body2",
                  weight: "regular",
                  density: "tight",
                }),
                color: uiColors.textMinor,
              }}
            >
              <Message
                id="c8bc2623-44b1-4f29-a404-971e950c2d92"
                context="Totals. Total's list. More items"
                default="· · ·"
              />
            </div>
          );
        } else return null;
      })}
    </div>
  );
};

const Values = ({
  action,
  valueInfo,
  status = "default",
}: {
  action?: TotalButton;
  valueInfo: [TotalValueInfo, TotalValueInfo] | TotalValueInfo;
  status?: TotalCardStatus;
}) => {
  const {
    textInfo: { direction },
  } = useLocale();

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "end",
      }}
    >
      <div
        css={{
          ...cssFns.overflow("hidden"),
          maskImage: `linear-gradient(
            ${direction === "ltr" ? "to right" : "to left"}, 
            black,
            black calc(100% - 26px),
            transparent calc(100% - 16px), transparent
          )`,
          paddingInlineEnd: "16px",
        }}
      >
        <div
          css={{
            ...cssFns.overflow("hidden"),
            whiteSpace: "nowrap",
            display: "flex",
            flexDirection: "column",
            rowGap: "8px",
          }}
        >
          {Array.isArray(valueInfo) ? (
            valueInfo.map((value, index) => {
              return <ValueInfo info={value} key={index} status={status} />;
            })
          ) : (
            <ValueInfo info={valueInfo} status={status} />
          )}
        </div>
      </div>
      {action && (
        <div>
          <Button
            {...action}
            view={status === "default" ? "default" : "contrast"}
            size="s"
          />
        </div>
      )}
    </div>
  );
};

const ValueStyle = (size: Size) => {
  const typo = useTypo();

  const result = {
    s: typo({
      level: "title3",
      weight: "regular",
      density: "normal",
    }),
    m: typo({
      level: "title2",
      weight: "regular",
      density: "normal",
    }),
    l: typo({
      level: "title1",
      weight: "regular",
      density: "normal",
    }),
  };

  return result[size];
};

const ValueInfo = ({
  info,
  status = "default",
}: {
  info: TotalValueInfo;
  status?: TotalCardStatus;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const ref = useRef<HTMLDivElement>(null);
  const hiddenRef = useRef<HTMLDivElement>(null);

  const { size, setSize } = useContext(ValueSizeContext);

  useLayoutEffect(() => {
    if (!ref.current || !hiddenRef.current) return;

    if (
      ref.current.clientWidth < hiddenRef.current.clientWidth &&
      size !== "s"
    ) {
      setSize(size === "l" ? "m" : "s");
    }
  }, [ref.current?.clientWidth, size, setSize]);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        rowGap: "2px",
        color: status === "default" ? uiColors.text : uiColors.everFront,
        position: "relative",
      }}
    >
      <div
        css={{
          ...typo({
            level: "caption1",
            weight: "regular",
            density: "tight",
          }),
        }}
      >
        {info.caption}
      </div>
      <div
        ref={ref}
        css={{
          ...ValueStyle(size),
        }}
      >
        {info.value}
      </div>
      <div
        ref={hiddenRef}
        aria-hidden="true"
        css={{
          ...ValueStyle(size),
          position: "absolute",
          visibility: "hidden",
        }}
      >
        {info.value}
      </div>
    </div>
  );
};

export const Total = (total: Total) => {
  const message = useMessage();
  const uiColors = useUiColors();

  const descriptionId = useId();

  const noData = message({
    id: "15a68514-0518-4457-93e2-dda63c01c39a",
    context: "Totals. Total card. No data",
    default: "No data",
  });

  const backgroundColorMap = {
    warning: uiColors.statuses.warning,
    error: uiColors.statuses.danger,
    default: uiColors.background,
  };

  let backgroundColor = uiColors.background;

  if (total.variant !== "list" && total.status) {
    backgroundColor = backgroundColorMap[total.status];
  }

  return (
    <div
      role="group"
      aria-label={message({
        id: "abe5f1e7-9e57-4225-a1b6-fe85aaf78982",
        context: "Totals. Total card",
        default: "Total card: {label}",
        values: { label: total.label },
      })}
      aria-describedby={descriptionId}
      css={{
        ...cssFns.border({ radius: "26px" }),
        height: "176px", // 208 - (16*2)
        backgroundColor: backgroundColor,
        ...cssFns.padding("16px"),
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateRows: "auto 1fr",
          rowGap: "8px",
          height: "100%",
        }}
      >
        <Header
          title={total.label}
          link={total.link}
          description={total.description}
          descriptionId={descriptionId}
          status={total.variant !== "list" ? total.status : undefined}
          informationPopover={total.informationPopover}
        />
        {total.variant === "list" ? (
          total.list.length !== 0 ? (
            <List items={total.list} />
          ) : (
            <Values valueInfo={{ value: noData }} />
          )
        ) : (
          <Values
            valueInfo={total.valueInfo ?? { value: noData }}
            action={total.valueInfo ? total.action : undefined}
            status={total.status}
          />
        )}
      </div>
    </div>
  );
};

const ScrollButton = ({
  ariaLabel,
  icon,
  onPress,
  align,
}: {
  ariaLabel: string;
  icon: ComponentType<{ className?: string }>;
  onPress: () => void;
  align: "start" | "end";
}) => {
  return (
    <div
      css={{
        position: "absolute",
        ...(align === "start"
          ? { insetInlineStart: "0" }
          : { insetInlineEnd: "0" }),
        top: "0",
        bottom: "0",
        display: "grid",
        alignContent: "center",
      }}
    >
      <Button
        view="floating"
        type="button"
        shape="circle"
        size="xs"
        ariaLabel={ariaLabel}
        icon={icon}
        onPress={onPress}
      />
    </div>
  );
};

const ValueSizeContext = createContext<{
  size: Size;
  setSize: (state: Size) => void;
}>({
  size: "l",
  setSize: () => {},
});

/**
 * Row of widgets with total info.
 *
 * It supports horizontal scrolling and resizing of value cards based on the width of the screen.
 * Also, if text doesn't fit in value cards, it will change its style to a smaller size or hide under a gradient.
 * Long text in list card is hidden under ellipsis.
 */
export const Totals = ({
  items,
}: {
  /**
   * Total card can be one of three variants:
   *  - "list": list of items with label and value, up to 6 items
   *  - "double-values": two values with optional captions
   *  - "single-value": single value with optional caption
   *
   * Recommended max length of array: 6.
   */
  items: Total[];
}) => {
  const message = useMessage();

  const isMobile = useIsMobile();
  const {
    textInfo: { direction },
  } = useLocale();

  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [currentTotalWidth, setCurrentTotalWidth] = useState(minTotalWidth);

  // Value text size
  const [size, setSize] = useState<Size>("l");
  const valueSize = { size, setSize };

  const [scrollStartPosition, setScrollStartPosition] = useState(0);
  const [scrollStartMax, setScrollStartMax] = useState(0);
  const { width: refWidth = 0 } = useResizeObserver({
    ref,
  });

  const scrollEnd = useCallback(() => {
    if (!ref.current) return;
    ref.current.scrollBy({ left: scrollStartMax, behavior: "smooth" });
  }, [ref, scrollStartMax]);

  const scrollStart = useCallback(() => {
    if (!ref.current) return;
    ref.current.scrollBy({ left: -scrollStartMax, behavior: "smooth" });
  }, [ref, scrollStartMax]);

  useLayoutEffect(() => {
    if (!ref.current || !containerRef.current) return;
    const container = containerRef.current;

    // Set width of total card
    let totalWidth = currentTotalWidth;
    if (container.scrollWidth > wideScreenMinWidth) {
      totalWidth = Math.floor((container.scrollWidth - 3 * rowGap) / 4);
    } else {
      totalWidth = isMobile
        ? Math.floor(container.scrollWidth * 0.8)
        : Math.floor((container.clientWidth - 2 * rowGap) / 3);
    }
    if (totalWidth !== currentTotalWidth) {
      setCurrentTotalWidth(
        totalWidth >= minTotalWidth ? totalWidth : minTotalWidth,
      );
    }

    // Set arrows
    const scrollContainer = ref.current;
    setScrollStartMax(scrollContainer.scrollWidth - refWidth);
    const onScroll = (e: Event) => {
      const target = e.target as HTMLUListElement;
      setScrollStartPosition(target.scrollLeft);
    };
    scrollContainer.addEventListener("scroll", onScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", onScroll);
    };
  }, [ref.current?.scrollWidth, refWidth, isMobile, currentTotalWidth]);

  const hasScrollStart = Math.abs(scrollStartPosition) > 0;
  const hasScrollEnd = scrollStartMax - Math.abs(scrollStartPosition) > 0;

  return (
    <Messages>
      <ValueSizeContext.Provider value={valueSize}>
        <div
          ref={containerRef}
          css={{
            position: "relative",
            display: "grid",
            flexGrow: "1",
            justifyContent: "start",
          }}
        >
          <div
            role="group"
            aria-label={message({
              id: "bcc1ce1e-0817-49b1-845a-c80b0edb2de5",
              context: "Totals. Totals row",
              default: "Totals row",
            })}
            ref={ref}
            css={{
              display: "grid",
              gridAutoFlow: "column",
              gridAutoColumns: `fit-content(${currentTotalWidth}px)`,
              columnGap: "16px",
              alignItems: "center",
              overflowX: "auto",
              scrollSnapType: "x proximity",
              scrollbarWidth: "none",
              ...(!isMobile && {
                maskImage: `linear-gradient(
              ${direction === "ltr" ? "to right" : "to left"}, 
              ${hasScrollStart ? "transparent, transparent 25px, black 50px" : "black"}, 
              black calc(100% - 50px), 
              ${hasScrollEnd ? "transparent calc(100% - 25px), transparent" : "black"}
            )`,
              }),
            }}
            __experimental_webkitScrollbarCss={{ display: "none" }}
          >
            {items.map((item, index) => (
              <div key={index} css={{ width: currentTotalWidth + "px" }}>
                <Total {...item} />
              </div>
            ))}
          </div>
          {!isMobile && hasScrollStart && (
            <ScrollButton
              align="start"
              ariaLabel={message({
                id: "5fb69471-1496-4f60-8b59-e8348353e632",
                context: "Totals. Totals row. Scroll arrow",
                default: "Scroll to the start arrow",
              })}
              icon={direction === "ltr" ? icons.ArrowLeft : icons.ArrowRight}
              onPress={() => {
                if (direction === "ltr") {
                  scrollStart();
                } else {
                  scrollEnd();
                }
              }}
            />
          )}
          {!isMobile && hasScrollEnd && (
            <ScrollButton
              align="end"
              ariaLabel={message({
                id: "4ba1e354-5bbd-4ce5-9884-5fe717e9236e",
                context: "Totals. Totals row. Scroll arrow",
                default: "Scroll to the end arrow",
              })}
              icon={direction === "ltr" ? icons.ArrowRight : icons.ArrowLeft}
              onPress={() => {
                if (direction === "ltr") {
                  scrollEnd();
                } else {
                  scrollStart();
                }
              }}
            />
          )}
        </div>
      </ValueSizeContext.Provider>
    </Messages>
  );
};

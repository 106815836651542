// @ts-ignore
// ts-expect-error was replaced with ts-ignore because error occurs on tsc execution in fleet-web repo
const __DEV__ = process.env.NODE_ENV === "development";

const m = new Set<string>();
const e = document.createElement("style");

document.head.append(e);

export const isInjected = (id: string) => {
  return m.has(id);
};

export const inject = (id: string, ...rules: string[]) => {
  for (const rule of rules) {
    if (__DEV__) {
      e.append(rule);
    } else {
      e.sheet!.insertRule(rule);
    }
  }
  m.add(id);
};

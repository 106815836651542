/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  type ForwardedRef,
  type PointerEvent,
  type ReactNode,
  type RefObject,
  useCallback,
} from "react";
import {
  mergeProps,
  useFocusRing,
  useOverlayPosition,
  usePress,
  useTooltip,
  useTooltipTrigger,
  type AriaTooltipProps,
  useCheckbox,
} from "react-aria";
import {
  useTooltipTriggerState,
  type TooltipTriggerState,
  useToggleState,
} from "react-stately";

import { cssFns, keyframes, useCss, type Style } from "@superweb/css";

import { useUiColors } from "../theme";
import { icons } from "../icons";
import { useTypo } from "../typo";
import { CheckboxInternal } from "../checkbox-internal";

import type { Column, LinkProps, SortingDirection } from "./types";
import type { SelectionState } from "./table";

const getSortIcon = (direction?: SortingDirection) => {
  if (direction === "asc") return icons.ArrowAscend;
  if (direction === "desc") return icons.ArrowDescend;
  return icons.ArrowSorting;
};

const useCellStyle = ({
  isFocusVisible,
  isHeaderCell,
  position,
  variant = "fill",
}: {
  isFocusVisible: boolean;
  isHeaderCell?: boolean;
  position?: {
    firstLeft?: boolean;
    firstRight?: boolean;
    lastLeft?: boolean;
    lastRight?: boolean;
  };
  variant?: "ghost" | "fill";
}): Style => {
  const uiColors = useUiColors();

  const bottomBorder =
    variant === "fill"
      ? isHeaderCell
        ? {
            borderBlockEndColor: cssFns.setOpacity(uiColors.text, 0.5),
            borderBlockEndStyle: "solid",
            borderBlockEndWidth: "1px",
          }
        : {
            borderBlockEndColor: uiColors.controlMinor,
            borderBlockEndStyle: "solid",
            borderBlockEndWidth: "1px",
          }
      : {};

  return {
    ...cssFns.padding("16px"),
    ...cssFns.overflow("hidden"),
    zIndex: "0",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minWidth: "32px",
    maxWidth: "320px",
    boxSizing: "border-box",
    outlineStyle: "none",
    ...(isFocusVisible &&
      cssFns.boxShadow({
        inset: true,
        spreadRadius: "2px",
        color: uiColors.focus,
      })),
    ...(position?.firstLeft && {
      borderStartStartRadius: "26px",
    }),
    ...(position?.firstRight && {
      borderStartEndRadius: "26px",
    }),
    ...(position?.lastLeft && {
      borderEndStartRadius: "26px",
    }),
    ...(position?.lastRight && {
      borderEndEndRadius: "26px",
    }),
    ...bottomBorder,
  };
};

type BaseProps = {
  children: ReactNode;
  align: Column<unknown>["align"];
  index: number;
  tabIndex?: number;
  isHovered?: boolean;
  isFooter?: boolean;
  updateFocusPointer: () => void;
};

export const HeaderCell = forwardRef(
  (
    {
      children,
      align = "start",
      sortable = false,
      sort,
      onSortChange,
      id,
      index,
      tabIndex,
      updateFocusPointer,
      stickyShift,
      variant = "fill",
    }: BaseProps & {
      sortable?: boolean;
      sort?: [string, SortingDirection];
      onSortChange?: (sort?: [string, SortingDirection]) => void;
      id: Column<unknown>["id"];
      stickyShift?: string;
      variant?: "fill" | "ghost";
    },
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const typo = useTypo();
    const { focusProps, isFocusVisible } = useFocusRing({ within: true });
    const uiColors = useUiColors();

    const style: Style = {
      ...useCellStyle({ isFocusVisible, isHeaderCell: true, variant }),
      ...typo({
        level: "caption1",
        weight: "medium",
        density: "tight",
      }),
      zIndex: "1",
      position: "sticky",
      top: stickyShift || "0",
      textAlign: align,
      ...(variant === "ghost"
        ? {
            backgroundColor: uiColors.backgroundMinor,
            color: uiColors.textMinor,
            height: "56px",
          }
        : {
            backgroundColor: uiColors.background,
            color: uiColors.text,
          }),
    };

    const curentDirection = sort?.[0] === id ? sort[1] : undefined;
    const [ariaSort, setAriaSort] = useState<
      "ascending" | "descending" | "none"
    >();

    useEffect(() => {
      if (!curentDirection) setAriaSort("none");
      if (curentDirection === "asc") setAriaSort("ascending");
      if (curentDirection === "desc") setAriaSort("descending");
    }, [curentDirection]);

    const { pressProps } = usePress({
      onPress: () => {
        if (!sortable) return;
        switch (curentDirection) {
          case undefined: {
            onSortChange?.([id, "desc"]);
            break;
          }
          case "desc": {
            onSortChange?.([id, "asc"]);
            break;
          }
          case "asc": {
            onSortChange?.(undefined);
            break;
          }
        }
      },
    });

    const Icon = getSortIcon(curentDirection);
    const iconClassName = useCss({
      ...cssFns.margin("0", "8px"),
      flexShrink: "0",
      width: "16px",
      height: "16px",
    });

    if (sortable) {
      return (
        <th
          ref={ref}
          aria-colindex={index}
          aria-sort={ariaSort}
          css={style}
          {...focusProps}
          onClick={(e) => {
            updateFocusPointer();
            (e.currentTarget.firstElementChild as HTMLElement).focus();
          }}
        >
          <span
            css={{
              display: "inline-flex",
              alignItems: "center",
              flexDirection: align === "start" ? "row" : "row-reverse",
              outlineStyle: "none",
              cursor: "pointer",
            }}
            tabIndex={tabIndex}
            {...pressProps}
          >
            {children}
            <Icon className={iconClassName} />
          </span>
        </th>
      );
    }

    return (
      <th
        css={style}
        aria-colindex={index}
        ref={ref}
        tabIndex={tabIndex}
        {...focusProps}
      >
        {children}
      </th>
    );
  },
);

export const Cell = forwardRef(
  (
    {
      children,
      align = "start",
      isHovered = false,
      isFooter = false,
      index,
      tabIndex,
      onPress,
      updateFocusPointer,
      tooltip,
      linkProps,
      variant = "fill",
      position,
    }: BaseProps & {
      linkProps?: LinkProps;
      onPress?: () => void;
      tooltip?: ReactNode;
      variant?: "fill" | "ghost";
      position: {
        firstLeft?: boolean;
        firstRight?: boolean;
        lastLeft?: boolean;
        lastRight?: boolean;
      };
    },
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const typo = useTypo();
    const uiColors = useUiColors();

    const { focusProps, isFocusVisible } = useFocusRing();
    const { pressProps } = usePress({
      onPress: () => {
        updateFocusPointer();
        onPress?.();
      },
    });

    const targetRef = useRef(null);
    const tooltipState = useTooltipTriggerState({ delay: 1200 });
    const { triggerProps, tooltipProps } = useTooltipTrigger(
      {},
      tooltipState,
      targetRef,
    );

    let cellProps = onPress ? mergeProps(focusProps, pressProps) : focusProps;
    cellProps = tooltip ? mergeProps(cellProps, triggerProps) : cellProps;

    const style: Style = {
      ...cssFns.overflow("hidden"),
      textOverflow: "ellipsis",
      display: "block",
      ...(variant === "ghost" && { lineHeight: "24px" }),
    };

    return (
      <td
        css={{
          ...useCellStyle({
            isFocusVisible,
            isHeaderCell: false,
            ...(variant === "ghost" && { position }),
            variant,
          }),
          ...typo({
            level: "body2",
            weight: "regular",
            density: "tight",
          }),
          ...cssFns.padding("0"),
          cursor: linkProps || onPress ? "pointer" : "default",
          textAlign: align,
          backgroundColor:
            isHovered || isFooter ? uiColors.hover : uiColors.background,
          color: uiColors.text,
        }}
        aria-colindex={index}
        ref={ref}
      >
        {linkProps ? (
          <a
            {...mergeProps(focusProps, pressProps, triggerProps)}
            href={linkProps.href}
            onClick={(e: PointerEvent<HTMLAnchorElement>) => {
              updateFocusPointer();
              onPress?.();
              linkProps.onClick?.(e);
            }}
            tabIndex={tabIndex}
            css={{
              color: uiColors.text,
              textDecorationLine: "none",
              outlineStyle: "none",
              ...cssFns.padding("16px"),
              ...style,
            }}
            ref={targetRef}
          >
            {children}
          </a>
        ) : (
          <div
            {...cellProps}
            tabIndex={tabIndex}
            css={{
              outlineStyle: "none",
              ...cssFns.padding("16px"),
              ...style,
            }}
            ref={targetRef}
          >
            {children}
          </div>
        )}
        {tooltip && tooltipState.isOpen && (
          <Tooltip
            state={tooltipState}
            content={tooltip}
            targetRef={targetRef}
            tooltipProps={tooltipProps}
          />
        )}
      </td>
    );
  },
);

const Tooltip = ({
  content,
  state,
  tooltipProps: baseTooltipProps,
  targetRef,
}: {
  content: ReactNode;
  state: TooltipTriggerState;
  tooltipProps: AriaTooltipProps;
  targetRef: RefObject<HTMLAnchorElement | HTMLTableCellElement>;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();
  const overlayRef = useRef(null);

  const { tooltipProps } = useTooltip(baseTooltipProps, state);
  const { overlayProps } = useOverlayPosition({
    targetRef,
    overlayRef,
    offset: 4,
    placement: "right",
  });

  return (
    <div {...overlayProps} ref={overlayRef}>
      <div
        {...tooltipProps}
        css={{
          textAlign: "start",
          maxWidth: "300px",
          boxSizing: "border-box",
          backgroundColor: uiColors.background,
          ...cssFns.border({ radius: "13px" }),
          ...cssFns.padding("9px", "12px"),
          ...cssFns.boxShadow({
            offsetY: "8px",
            blurRadius: "20px",
            color: cssFns.setOpacity(uiColors.everBack, 0.12),
          }),
          whiteSpace: "normal",
          wordWrap: "break-word",
          pointerEvents: "none",
          animationName: keyframes({
            0: {
              transform: "translateX(20px)",
              opacity: "0",
            },
            100: {
              transform: "translateX(0px)",
              opacity: "1",
            },
          }),
          animationDuration: "0.2s",
          animationTimingFunction: "ease",
        }}
      >
        <span
          css={{
            ...typo({
              level: "caption1",
              weight: "regular",
              density: "tight",
            }),
          }}
        >
          {content}
        </span>
      </div>
    </div>
  );
};

export const CheckboxHeaderCell = forwardRef(
  (
    {
      index,
      stickyShift,
      tabIndex,
      selection,
      onChange,
      inputRef,
      variant = "fill",
    }: {
      index: number;
      stickyShift?: string;
      tabIndex?: number;
      selection: SelectionState;
      onChange: (state: SelectionState) => void;
      inputRef: RefObject<HTMLInputElement>;
      variant?: "fill" | "ghost";
    },
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const uiColors = useUiColors();

    const style: Style = {
      ...useCellStyle({ isFocusVisible: false, isHeaderCell: true, variant }),
      zIndex: "1",
      position: "sticky",
      top: stickyShift || "0",
      ...(variant === "ghost"
        ? {
            backgroundColor: uiColors.backgroundMinor,
            minHeight: "56px",
          }
        : {
            backgroundColor: uiColors.background,
          }),
    };

    const onHeaderCheckboxValueChange = useCallback(
      (isSelected: boolean) => {
        onChange(
          isSelected
            ? {
                all: isSelected,
                exclude: new Set<string>(),
              }
            : {
                all: isSelected,
                include: new Set<string>(),
              },
        );
      },
      [onChange],
    );

    const toggleHeaderCheckboxState = useToggleState({
      isSelected: selection.all,
      onChange: onHeaderCheckboxValueChange,
    });

    const indeterminate = selection.all && selection.exclude.size !== 0;

    const { inputProps } = useCheckbox(
      {
        isSelected: selection.all,
        onChange: onHeaderCheckboxValueChange,
        "aria-label": "Select all",
        isIndeterminate: indeterminate,
      },
      toggleHeaderCheckboxState,
      inputRef,
    );

    return (
      <th css={style} aria-colindex={index} ref={ref}>
        <CheckboxInternal
          value={selection.all}
          indeterminate={indeterminate}
          inputProps={{
            ...inputProps,
            "aria-checked": indeterminate
              ? "mixed"
              : selection.all
                ? "true"
                : "false",
            tabIndex,
          }}
          inputRef={inputRef}
        />
      </th>
    );
  },
);

export const CheckboxCell = forwardRef(
  (
    {
      checkboxKey,
      selection,
      onChange,
      inputRef,
      tabIndex,
      isHovered = false,
      index,
      variant = "fill",
      position,
    }: {
      checkboxKey: string;
      selection: SelectionState;
      onChange: (state: SelectionState) => void;
      inputRef: RefObject<HTMLInputElement>;
      tabIndex: number;
      isHovered?: boolean;
      index: number;
      variant?: "fill" | "ghost";
      position: {
        firstLeft?: boolean;
        lastLeft?: boolean;
      };
    },
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const uiColors = useUiColors();

    const value = selection.all
      ? !selection.exclude.has(checkboxKey)
      : selection.include.has(checkboxKey);

    const onValueChange = useCallback(
      (isSelected: boolean) => {
        let res;
        if (selection.all) {
          res = new Set<string>(selection.exclude);
          if (isSelected) {
            res.delete(checkboxKey);
          } else {
            res.add(checkboxKey);
          }
        } else {
          res = new Set<string>(selection.include);
          if (isSelected) {
            res.add(checkboxKey);
          } else {
            res.delete(checkboxKey);
          }
        }
        onChange(
          selection.all
            ? { all: selection.all, exclude: res }
            : {
                all: selection.all,
                include: res,
              },
        );
      },
      [checkboxKey, selection, onChange],
    );

    const toggleState = useToggleState({
      isSelected: value,
      onChange: onValueChange,
    });

    const { inputProps } = useCheckbox(
      {
        isSelected: value,
        onChange: onValueChange,
        "aria-label": "Select",
      },
      toggleState,
      inputRef,
    );

    return (
      <td
        css={{
          ...useCellStyle({
            isFocusVisible: false,
            isHeaderCell: false,
            ...(variant === "ghost" && { position }),
            variant,
          }),
          backgroundColor: isHovered ? uiColors.hover : uiColors.background,
        }}
        aria-colindex={index}
        ref={ref}
      >
        <CheckboxInternal
          inputProps={{ ...inputProps, tabIndex }}
          inputRef={inputRef}
          value={value}
        />
      </td>
    );
  },
);

import { isInjected, inject } from "./instance";
import { dash, trim, hash, decl, name } from "./utils";

export type FontFace = {
  src: string;
  ascentOverride?: string;
  descentOverride?: string;
  fontDisplay?: string;
  fontStretch?: string;
  fontStyle?: string;
  fontWeight?: string;
  fontVariant?: string;
  fontFeatureSettings?: string;
  fontVariationSettings?: string;
  lineGapOverride?: string;
  unicodeRange?: string;
};

/**
 * Applies `@font-face` atomically
 *
 * @example
 * ```ts
 * const someFont = fontFace({
 *   src: `url(${fontUrl})`
 * });
 *
 * <p
 *   className={css({
 *     fontFamily: someFont,
 *   })}
 * />
 * ```
 *
 * @param fontFaces - font-face options objects
 * @returns generated fontFamily value
 */
export const fontFace = (...fontFaces: FontFace[]) => {
  let h = hash();
  const rules = [];
  for (const ff of fontFaces) {
    const entries = Object.entries(ff)
      .filter((e): e is [string, string] => Boolean(e[1]))
      .sort(([l], [r]) => (l < r ? -1 : l > r ? 1 : 0));
    let rule = "";
    for (const [property, value] of entries) {
      const p = dash(property);
      const v = trim(value);
      h = hash(p);
      h = hash(v);
      rule += `${decl(p, v)};`;
    }
    rules.push(rule);
  }
  const n = name(h);
  if (!isInjected(n)) {
    inject(n, ...rules.map((rule) => `@font-face{font-family:${n};${rule}}`));
  }
  return n;
};

import type { Style } from "./style";
import { isInjected, inject } from "./instance";
import { dash, trim, hash, decl, name } from "./utils";

export type Keyframes = {
  [P: number]: Style;
};

/**
 * Apply `@keyframes` atomically
 *
 * @example
 * ```tsx
 * const a = keyframes({
 *   0: {
 *     top: "0"
 *   },
 *   100: {
 *     top: "100px"
 *   }
 * });
 *
 * <p
 *   className={css({
 *     animationName: a,
 *   })}
 * />
 * ```
 *
 * @param keyframes - keyframes object
 * @returns generated animation name
 */
export const keyframes = (keyframes: Keyframes) => {
  let h = hash();
  const entriesOfStops = Object.entries(keyframes);
  let rules = "";

  for (const [stop, style] of entriesOfStops) {
    const entries = Object.entries(style)
      .filter((e): e is [string, string] => Boolean(e[1]))
      .sort(([l], [r]) => (l < r ? -1 : l > r ? 1 : 0));

    h = hash(stop, h);
    rules += `${stop}%{`;

    for (const [property, value] of entries) {
      const p = dash(property);
      const v = trim(value);
      h = hash(p, h);
      h = hash(v, h);
      rules += `${decl(p, v)};`;
    }

    rules += "}";
  }

  const n = name(h);

  if (!isInjected(n)) {
    inject(n, `@keyframes ${n} {${rules}}`);
  }

  return n;
};

import { Temporal } from "@js-temporal/polyfill";
import { useNow } from "@superweb/intl";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type HTMLAttributes,
  type KeyboardEvent,
} from "react";
import { usePreviousState } from "../state";
import type { CalendarProps, CalendarState, CalendarView } from "./types";

const getWeeks = (date: Temporal.PlainDate) => {
  const data: Temporal.PlainDate[][] = [];

  const firstDayOfMonth = date.with({ day: 1 });
  const monthStartsAt = firstDayOfMonth.dayOfWeek;

  const { daysInMonth, daysInWeek } = date;

  const weeksInMonth = Math.ceil(
    (monthStartsAt + daysInMonth - 1) / daysInWeek,
  );

  for (let weekIndex = 0; weekIndex < weeksInMonth; weekIndex++) {
    data.push([]);

    for (let dayIndex = 0; dayIndex < daysInWeek; dayIndex++) {
      const days = weekIndex * daysInWeek + dayIndex - monthStartsAt + 1;
      const value = firstDayOfMonth.add({ days });
      data[weekIndex]!.push(value);
    }
  }

  return data;
};

const getMonths = (date: Temporal.PlainDate) => {
  const data: Temporal.PlainDate[] = [];

  for (let month = 1; month <= date.monthsInYear; month++) {
    data.push(date.with({ month }));
  }

  return data;
};

export const useCalendar = ({
  onChange,
  min,
  max,
  value,
  disabled = false,
  smallestUnit = "day",
  autoFocus = false,
}: CalendarProps): {
  state: CalendarState;
  gridProps: HTMLAttributes<HTMLElement>;
} => {
  const isValid = useCallback(
    (value: Temporal.PlainDate, type: CalendarView = "day") => {
      switch (type) {
        case "month":
          return (
            (min
              ? min.year < value.year ||
                (min.year === value.year && min.month <= value.month)
              : true) &&
            (max
              ? max.year > value.year ||
                (max.year === value.year && max.month >= value.month)
              : true)
          );
        case "day":
          return (
            (min ? Temporal.PlainDate.compare(min, value) !== 1 : true) &&
            (max ? Temporal.PlainDate.compare(value, max) !== 1 : true)
          );
      }
    },
    [min, max],
  );

  const prevValue = usePreviousState(value);
  const now = useNow("day").toPlainDate();

  const [anchorValue, setAnchorValue] = useState(
    value ??
      // if now is less than min
      (min && Temporal.PlainDate.compare(now, min) === -1
        ? min
        : // if now is more than max
          max && Temporal.PlainDate.compare(max, now) === -1
          ? max
          : now),
  );

  const [view, setView] = useState<CalendarView>(smallestUnit);

  const [focusedValue, setFocusedValue] = useState<
    Temporal.PlainDate | undefined
  >(disabled || !autoFocus ? undefined : anchorValue);

  useEffect(() => {
    if (value && value !== prevValue) {
      setAnchorValue(value);
    }
  }, [value, prevValue, setAnchorValue]);

  const isNextViewAvailable = useCallback(() => {
    if (smallestUnit === "day" && view === "month") return true;

    return false;
  }, [view, smallestUnit]);

  const isPrevViewAvailable = useCallback(() => {
    if (smallestUnit === "day" && view === "day") return true;

    return false;
  }, [view, smallestUnit]);

  const goToNextView = useCallback(() => {
    if (view === "month") {
      setView("day");
    }
  }, [view, setView]);

  const goToPrevView = useCallback(() => {
    if (view === "day") {
      setView("month");
    }
  }, [view, setView]);

  const setCalendarView = useCallback(
    (view: CalendarView) => {
      setView(view);
      if (value !== undefined) {
        setFocusedValue(value);
      } else if (autoFocus) {
        setFocusedValue(anchorValue);
      }
    },
    [setView, value, anchorValue, autoFocus],
  );

  const selectDate = useCallback(
    (nextDate: Temporal.PlainDate) => {
      if (!disabled) {
        setAnchorValue(nextDate);
        setFocusedValue(nextDate);

        if (isNextViewAvailable()) {
          goToNextView();
        } else {
          onChange?.(nextDate);
        }
      }
    },
    [onChange, isNextViewAvailable, goToNextView, disabled],
  );

  const isDaysView = useMemo(() => view === "day", [view]);

  const weeks = useMemo(
    () => (isDaysView ? getWeeks(anchorValue) : []),
    [anchorValue, isDaysView],
  );

  const months = useMemo(
    () => (!isDaysView ? getMonths(anchorValue) : []),
    [anchorValue, isDaysView],
  );

  const tryToFocus = useCallback(
    (value: Temporal.PlainDate) => {
      if (isValid(value)) {
        setFocusedValue(value);
        setAnchorValue(value);
      }
    },
    [setAnchorValue, setFocusedValue, isValid],
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();

      if (focusedValue === undefined) return;

      switch (e.key) {
        case "ArrowLeft": {
          tryToFocus(
            focusedValue.subtract(isDaysView ? { days: 1 } : { months: 1 }),
          );
          break;
        }
        case "ArrowRight": {
          tryToFocus(
            focusedValue.add(isDaysView ? { days: 1 } : { months: 1 }),
          );
          break;
        }
        case "ArrowUp": {
          tryToFocus(
            focusedValue.subtract(isDaysView ? { weeks: 1 } : { months: 3 }),
          );
          break;
        }
        case "ArrowDown": {
          tryToFocus(
            focusedValue.add(isDaysView ? { weeks: 1 } : { months: 3 }),
          );
          break;
        }
        case "Enter": {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (focusedValue && !focusedValue.equals(anchorValue)) {
            selectDate(focusedValue);
          }
        }
      }
    },
    [focusedValue, anchorValue, isDaysView, tryToFocus, selectDate],
  );

  return {
    state: {
      selectDate,
      setDate: setAnchorValue,
      setFocus: setFocusedValue,
      setView: setCalendarView,
      isValid,
      isNextViewAvailable,
      isPrevViewAvailable,
      goToNextView,
      goToPrevView,
      view,
      weeks,
      months,
      disabled,
      value,
      focusedValue,
      anchorValue,
    },
    gridProps: {
      onKeyDown,
      role: "grid",
      "aria-disabled": disabled,
    },
  };
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, type ReactNode, type PointerEvent } from "react";
import { mergeProps, useButton, useFocusRing, useHover } from "react-aria";

import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";

import { useUiColors, useBrandColors } from "../theme";
import { Tooltip, useTooltip } from "../tooltip";

import type { MenuGroup, MenuItem, MenuLink } from "./menu";
import type { NavMenuState } from "./menu-state";
import { SubMenu } from "./desktop-sub-menu";

export const DesktopMenu = ({
  state,
  logo,
  primaryItems,
  secondaryItems,
}: {
  state: NavMenuState;
  logo: ReactNode;
  primaryItems: MenuItem[];
  secondaryItems: MenuItem[];
}) => {
  const brandColors = useBrandColors();

  return (
    <nav
      css={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...cssFns.gap("16px"),
        ...cssFns.margin("0"),
        ...cssFns.padding("12px", "0"),
        backgroundColor: brandColors.backgroundMinor,
        overflowY: "clip",
      }}
    >
      <Corner type="top" />

      <Section>
        <LogoItem logo={logo} />

        {primaryItems.map((item, index) => (
          <Item key={index} state={state} item={item} index={index} />
        ))}
      </Section>

      <Section>
        {secondaryItems.map((item, index) => (
          <Item
            key={index}
            state={state}
            item={item}
            index={primaryItems.length + index}
          />
        ))}
      </Section>

      <Corner type="bottom" />
    </nav>
  );
};

const Section = ({ children }: { children: ReactNode }) => {
  return (
    <ul
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...cssFns.margin("0"),
        ...cssFns.padding("0"),
        listStyleType: "none",
      }}
    >
      {children}
    </ul>
  );
};

const LogoItem = ({ logo }: { logo: ReactNode }) => {
  return (
    <li
      css={{
        width: "40px",
        height: "40px",
        marginBlockEnd: "8px",
      }}
    >
      {logo}
    </li>
  );
};

const Item = ({
  state,
  item,
  index,
}: {
  state: NavMenuState;
  item: MenuItem;
  index: number;
}) => {
  switch (item.type) {
    case "link":
      return <Link state={state} item={item} />;
    case "group":
      return <Group state={state} item={item} index={index} />;
  }
};

const Link = ({ state, item }: { state: NavMenuState; item: MenuLink }) => {
  const uiColors = useUiColors();
  const brandColors = useBrandColors();
  const ref = useRef<HTMLAnchorElement>(null);

  const { isHovered, hoverProps } = useHover({});
  const { triggerProps, tooltipProps, state: tooltipState } = useTooltip(ref);

  return (
    <li
      css={{
        height: "56px",
        width: "64px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <a
        {...mergeProps(hoverProps, triggerProps)}
        aria-label={item.label}
        id={item.id}
        href={item.link.href}
        onClick={(e: PointerEvent<HTMLAnchorElement>) => {
          item.link.onClick?.(e);
          state.closeSubMenu();
        }}
        target={item.link.target}
        css={{
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...cssFns.border({ width: "0", radius: "13px" }),
          cursor: "pointer",
          color: item.link.isActive ? brandColors.text : brandColors.textMinor,
          backgroundColor:
            item.link.isActive || isHovered ? uiColors.hover : "transparent",
          outlineColor: brandColors.text,
        }}
        ref={ref}
      >
        {item.icon}
      </a>
      {tooltipState.isOpen && (
        <Tooltip
          state={tooltipState}
          tooltipProps={tooltipProps}
          targetRef={ref}
          placement="end"
        >
          {item.label}
        </Tooltip>
      )}
    </li>
  );
};

const Group = ({
  state,
  item,
  index,
}: {
  state: NavMenuState;
  item: MenuGroup;
  index: number;
}) => {
  const uiColors = useUiColors();
  const brandColors = useBrandColors();

  const { textInfo } = useLocale();

  const { isFocusVisible, focusProps } = useFocusRing();
  const { isHovered: isButtonHovered, hoverProps: hoverButtonProps } = useHover(
    {},
  );

  const isOpen = index === state.openedSubmenuIndex;

  const isActive = item.items.some((groupItem) => {
    return (
      groupItem &&
      groupItem.items.some((subGroupItem) => {
        return subGroupItem && subGroupItem.link.isActive;
      })
    );
  });
  const ref = useRef<HTMLButtonElement>(null);

  const openOnClick = () => {
    if (state.submenuTriggerType === "hover") {
      state.openSubMenu(index);
      return;
    }

    if (
      state.openedSubmenuIndex === undefined ||
      state.openedSubmenuIndex !== index
    ) {
      state.openSubMenu(index);
    } else {
      state.closeSubMenu();
    }
  };

  const openOnHover = () => {
    if (state.submenuTriggerType === "click") return;

    const { activeElement } = document;
    if (activeElement instanceof HTMLElement) {
      activeElement.blur();
    }
    ref.current?.click();
  };

  const closeOnHover = () => {
    if (state.submenuTriggerType === "click") return;

    state.closeSubMenu();
  };

  const { buttonProps } = useButton(
    { "aria-label": item.label, onPress: openOnClick },
    ref,
  );

  if (item.items.length === 0) return <></>;

  return (
    <li
      css={{
        height: "56px",
        width: "64px",
      }}
      onMouseEnter={openOnHover}
      onMouseLeave={closeOnHover}
    >
      <div
        css={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          {...mergeProps(buttonProps, focusProps, hoverButtonProps)}
          id={item.id}
          ref={ref}
          css={{
            width: "40px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...cssFns.border({ width: "0", radius: "13px" }),
            cursor: "pointer",
            color: isActive ? brandColors.text : brandColors.textMinor,
            backgroundColor:
              isActive || isOpen || isButtonHovered || isFocusVisible
                ? uiColors.hover
                : "transparent",
            outlineStyle: "none",
          }}
        >
          {item.icon}
        </button>
        {state.submenuTriggerType === "hover" && isOpen && (
          <>
            <div
              css={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: "-30px",
                insetInlineEnd: "-60px",
                zIndex: "1",
                transform:
                  textInfo.direction === "rtl"
                    ? "rotate(-45deg)"
                    : "rotate(45deg)",
              }}
            />
            <div
              css={{
                width: "100px",
                height: "100px",
                position: "absolute",
                bottom: "-30px",
                insetInlineEnd: "-60px",
                zIndex: "1",
                transform:
                  textInfo.direction === "rtl"
                    ? "rotate(-45deg)"
                    : "rotate(45deg)",
              }}
            />
          </>
        )}
      </div>

      {isOpen && <SubMenu state={state} group={item} />}
    </li>
  );
};

const Corner = ({ type }: { type: "top" | "bottom" }) => {
  const brandColors = useBrandColors();
  const { textInfo } = useLocale();

  return (
    <div
      css={{
        width: "24px",
        height: "24px",
        zIndex: "2",
        position: "absolute",
        insetInlineEnd: "-24px",

        ...(type === "top"
          ? {
              insetBlockStart: "0",
            }
          : { insetBlockEnd: "0", transform: "rotate(-90deg)" }),

        ...(textInfo.direction === "rtl" && {
          transform: type === "top" ? "rotate(90deg)" : "rotate(180deg)",
        }),
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M24 0H0V24C0 10.7452 10.7452 0 24 0Z"
          fill={brandColors.backgroundMinor}
        />
      </svg>
    </div>
  );
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useRef,
  type ReactNode,
  type RefObject,
  Fragment,
  type MutableRefObject,
  useState,
  type PointerEvent,
  useContext,
  useLayoutEffect,
} from "react";
import {
  DismissButton,
  Overlay,
  useButton,
  useMenuTrigger,
  usePopover,
  type AriaButtonProps,
} from "react-aria";
import { useMenuTriggerState, type OverlayTriggerState } from "react-stately";

import { cssFns } from "@superweb/css";

import { InternalButton } from "../buttons/internal-button";
import { Button } from "../buttons/button";
import { useUiColors } from "../theme";
import { icons } from "../icons";
import { useTypo } from "../typo";
import { DialogContext } from "../dialog";

import type {
  MenuItem,
  MenuLink as MenuLinkItem,
  MenuGroup as MenuGroupItem,
  SubMenuGroup as SubMenuGroupItem,
  SubMenuLink as SubMenuLinkItem,
} from "./menu";
import {
  useMobileMenuState,
  type NavMenuState,
  type MobileMenuState,
} from "./menu-state";
import { isValidSubGroup } from "./group-validator";

export const MobileMenu = ({
  logo,
  title,
  state,
  primaryItems,
  secondaryItems,
}: {
  logo: ReactNode;
  title: string;
  state: NavMenuState;
  primaryItems: MenuItem[];
  secondaryItems: MenuItem[];
}) => {
  const ref = useRef(null);
  const triggerState = useMenuTriggerState({});
  const mobileState = useMobileMenuState(state, triggerState);

  const { menuTriggerProps } = useMenuTrigger(
    { type: "menu" },
    triggerState,
    ref,
  );

  return (
    <>
      {!mobileState.popoverState.isOpen && (
        <BurgerMenuButton buttonRef={ref} ariaButtonProps={menuTriggerProps} />
      )}
      {mobileState.popoverState.isOpen && (
        <MenuPopover state={mobileState.popoverState} triggerRef={ref}>
          <MenuHeader
            logo={logo}
            title={title}
            onClose={() => {
              mobileState.popoverState.close();
            }}
          />
          <div
            css={{
              ...cssFns.padding("0", "16px"),
            }}
          >
            <Menu
              state={mobileState}
              primaryItems={primaryItems}
              secondaryItems={secondaryItems}
            />
          </div>
        </MenuPopover>
      )}
    </>
  );
};

const BurgerMenuButton = ({
  buttonRef,
  ariaButtonProps,
}: {
  buttonRef: MutableRefObject<null>;
  ariaButtonProps: AriaButtonProps<"button">;
}) => {
  return (
    <InternalButton
      buttonRef={buttonRef}
      ariaButtonProps={ariaButtonProps}
      view="ghost"
      ariaLabel="Open menu"
      icon={icons.Menu}
    />
  );
};

const MenuPopover = ({
  state,
  triggerRef,
  children,
}: {
  state: OverlayTriggerState;
  triggerRef: RefObject<HTMLButtonElement>;
  children: ReactNode;
}) => {
  const uiColors = useUiColors();

  const popoverRef = useRef(null);

  const { popoverProps, underlayProps } = usePopover(
    {
      popoverRef,
      triggerRef,
    },
    state,
  );
  popoverProps.style = undefined;

  const dialogContext = useContext(DialogContext);
  if (!dialogContext)
    throw new Error("Dialog must have an access to DialogContext");
  const { onStackChange } = dialogContext;

  const [identity] = useState({});

  useLayoutEffect(() => {
    onStackChange((stack) => [...stack, identity]);

    return () => {
      onStackChange((stack) => stack.filter((d) => d !== identity));
    };
  }, [identity, onStackChange]);

  return (
    <Overlay>
      <div
        {...underlayProps}
        css={{
          position: "fixed",
          ...cssFns.inset("0"),
        }}
      />
      <div
        {...popoverProps}
        ref={popoverRef}
        css={{
          position: "fixed",
          width: "100vw",
          height: "100%",
          backgroundColor: uiColors.backgroundMinor,
          ...cssFns.inset("0"),
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};

const MenuHeader = ({
  logo,
  title,
  onClose,
}: {
  logo: ReactNode;
  title: string;
  onClose: () => void;
}) => {
  const typo = useTypo();

  return (
    <div>
      <div
        css={{
          height: "56px",
          display: "grid",
          gridTemplateColumns: "auto 1fr auto",
          alignItems: "center",
          paddingInlineStart: "16px",
        }}
      >
        <div css={{ marginInlineEnd: "6px" }}>{logo}</div>

        <div css={{ display: "flex", alignItems: "center" }}>
          <span
            css={typo({
              level: "body1",
              weight: "medium",
              density: "tight",
            })}
          >
            {title}
          </span>
        </div>

        <Button
          view="ghost"
          ariaLabel="Close menu"
          icon={icons.Cross}
          onPress={onClose}
        />
      </div>
    </div>
  );
};

const Menu = ({
  primaryItems,
  secondaryItems,
  state,
}: {
  primaryItems: MenuItem[];
  secondaryItems: MenuItem[];
  state: MobileMenuState;
}) => {
  return (
    <nav
      css={{
        position: "relative",
        isolation: "isolate",
        zIndex: "1",
        display: "grid",
        gridTemplateRows: "1fr",
        ...cssFns.gap("13px"),
      }}
    >
      <Section>
        {primaryItems.map((item, index) => (
          <Fragment key={index}>
            <Item item={item} state={state} />
            {index !== primaryItems.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Section>
      <Section>
        {secondaryItems.map((item, index) => (
          <Fragment key={index}>
            <Item item={item} state={state} />
            {index !== secondaryItems.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Section>
    </nav>
  );
};

const Section = ({ children }: { children: ReactNode }) => {
  const uiColors = useUiColors();

  return (
    <ul
      css={{
        display: "grid",
        alignItems: "center",
        ...cssFns.margin("0"),
        ...cssFns.border({ radius: "13px" }),
        ...cssFns.padding("0"),
        listStyleType: "none",
        color: uiColors.text,
        backgroundColor: uiColors.background,
      }}
    >
      {children}
    </ul>
  );
};

const Item = ({ item, state }: { item: MenuItem; state: MobileMenuState }) => {
  switch (item.type) {
    case "link":
      return <Link item={item} state={state} />;
    case "group":
      return <Group item={item} state={state} />;
  }
};

const Link = ({
  item,
  state,
}: {
  item: MenuLinkItem;
  state: MobileMenuState;
}) => {
  const onPress = (e: PointerEvent<HTMLAnchorElement>) => {
    item.link.onClick?.(e);
    state.popoverState.close();
  };

  return (
    <li>
      <a
        id={item.id}
        href={item.link.href}
        onClick={onPress}
        onPointerUp={onPress}
        target={item.link.target}
        css={{
          width: "100%",
          height: "48px",

          display: "grid",
          gridTemplateColumns: "48px 1fr",
          alignItems: "center",
          textDecorationLine: "none",
          cursor: "pointer",
        }}
      >
        <ItemIcon>{item.icon}</ItemIcon>
        <ItemLabel>{item.label}</ItemLabel>
      </a>
    </li>
  );
};

const Group = ({
  item,
  state,
}: {
  item: MenuGroupItem;
  state: MobileMenuState;
}) => {
  const [isOpen, setOpen] = useState(false);

  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      onPress: () => {
        setOpen((prevState) => !prevState);
      },
    },
    ref,
  );

  if (item.items.length === 0) return <></>;

  return (
    <>
      <li>
        <button
          {...buttonProps}
          id={item.id}
          ref={ref}
          css={{
            width: "100%",
            height: "48px",
            display: "grid",
            gridTemplateColumns: "48px auto 48px ",
            alignItems: "center",
            ...cssFns.border({ width: "0" }),
            ...cssFns.padding("0"),
            backgroundColor: "transparent",
            cursor: "pointer",
            outlineStyle: "none",
          }}
        >
          <ItemIcon>{item.icon}</ItemIcon>
          <ItemLabel>{item.label}</ItemLabel>

          <ItemIcon>
            <div
              css={{
                transitionDuration: "300ms",
                ...(isOpen && { transform: "rotate(180deg)" }),
              }}
            >
              <icons.ChevronDown />
            </div>
          </ItemIcon>
        </button>
      </li>
      {isOpen &&
        item.items.map((item, index) => (
          <SubMenuGroup key={index} item={item} state={state} />
        ))}
    </>
  );
};

const SubMenuGroup = ({
  item,
  state,
}: {
  item: SubMenuGroupItem | undefined | null | false;
  state: MobileMenuState;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  if (!item || !isValidSubGroup(item) || item.items.length === 0) return <></>;

  return (
    <>
      {item.label && (
        <div
          css={{
            width: "100%",
            height: "48px",
            display: "flex",
            alignItems: "center",
            paddingInlineStart: "48px",
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
              ...typo({ level: "body2", weight: "medium", density: "tight" }),
              color: uiColors.textMinor,
            }}
          >
            {item.label}
          </div>
        </div>
      )}

      {item.items.map(
        (item, index) =>
          item && <SubMenuLink key={index} item={item} state={state} />,
      )}
    </>
  );
};

const SubMenuLink = ({
  item,
  state,
}: {
  item: SubMenuLinkItem;
  state: MobileMenuState;
}) => {
  const onPress = (e: PointerEvent<HTMLAnchorElement>) => {
    if (!item.link.onClick) return;

    item.link.onClick(e);
    state.popoverState.close();
  };

  return (
    <li>
      <a
        id={item.id}
        href={item.link.href}
        onClick={onPress}
        onPointerUp={onPress}
        target={item.link.target}
        css={{
          width: "100%",
          height: "48px",
          display: "grid",
          paddingInlineStart: "48px",
          textDecorationLine: "none",
          cursor: "pointer",
        }}
      >
        <ItemLabel>{item.label}</ItemLabel>
      </a>
    </li>
  );
};

const ItemIcon = ({ children }: { children: ReactNode }) => {
  const uiColors = useUiColors();

  return (
    <div
      css={{
        width: "48px",
        height: "48px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: uiColors.textMinor,
      }}
    >
      {children}
    </div>
  );
};

const ItemLabel = ({ children }: { children: ReactNode }) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",

        ...typo({ level: "body2", weight: "regular", density: "tight" }),
        color: uiColors.text,
        textDecorationStyle: "none",
      }}
    >
      {children}
    </div>
  );
};

const Divider = () => {
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "grid",
        marginInlineStart: "48px",
        borderBottomWidth: "0.5px",
        borderBottomStyle: "solid",
        boxSizing: "border-box",
        borderBottomColor: uiColors.line,
      }}
    />
  );
};

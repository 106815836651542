import { createContext, useContext, type ReactNode } from "react";

export type CssOptionsValue = {
  /**
   * If enabled, all unknown or unsupported CSS properties will be injected.
   *
   * ⚠️ Caution!
   *
   * This library implements Atomic CSS approach.
   *
   * It has many benefits; but the limitation is that every individual
   * CSS property is injected as a separate atomic CSS rule
   * in a non-deterministic order.
   *
   * It's fine as long as CSS properties do not override each other.
   * But if you try to combine e.g. `margin` and `marginTop` the end result
   * will depend on rule order, which may change depending on how the user
   * navigates through the app.
   *
   * @defaultValue true
   */
  dangerouslyAllowUnknownProperties?: boolean;
};

const CssOptionsContext = createContext<CssOptionsValue>({
  dangerouslyAllowUnknownProperties: true,
});

export const useCssOptions = () => {
  return useContext(CssOptionsContext);
};

export const CssOptions = ({
  value,
  children,
}: {
  value: CssOptionsValue;
  children: ReactNode;
}) => {
  return (
    <CssOptionsContext.Provider value={value}>
      {children}
    </CssOptionsContext.Provider>
  );
};

// https://a.yandex-team.ru/arcadia/taxi/schemas/schemas/services/media-storage/api/v2_media.yaml

import { useMutation, type UseMutationOptions } from "@superweb/api";

import { fetch, type HttpError } from "#internal/api/fetch";
import type { LicenseCountryISO } from "#internal/api/hiring-candidates/definitions";

type UseUploadFileParams = {
  /** @description Изображение документа */
  file: File;
  /** @description Источник запроса */
  source: string;
  /** @description type of service, e.g. scout-identity-card */
  type: string;
  /** @description Страна для хранения документа(ISO 3166) */
  origin: LicenseCountryISO;
  /** @description Токен идемпотентности сгенерированный клиентом */
  idempotencyToken: string;
  /**
   * @description Уникальный идентификатор медиа данных. Может быть использован для обновления ранее загруженных
   */
  id?: string;
  /**
   * @description Версия документа, которую следует обновить
   */
  version?: string;
};

type StoreResponse = {
  id: string;
  version: string;
};

type UseUploadFileOptions = UseMutationOptions<
  StoreResponse,
  HttpError,
  UseUploadFileParams
>;

/** Сохранить или заменить медиа данные */
export const useUploadFile = (options?: UseUploadFileOptions) => {
  return useMutation(
    ({
      file,
      source,
      type,
      origin,
      idempotencyToken,
      id,
      version,
    }: UseUploadFileParams) => {
      const searchParams = new URLSearchParams({ source, origin });
      if (id && version) {
        searchParams.append("id", id);
        searchParams.append("version", version);
      }

      const url = `/api/service/${type}/v2/store?${searchParams.toString()}`;

      return fetch(
        new Request(url, {
          method: "POST",
          headers: {
            "X-Idempotency-Token": idempotencyToken,
            "Content-Type": file.type,
          },
          body: file,
        }),
        {
          200: async (r) => (await r.json()) as StoreResponse,
        },
      );
    },
    options,
  );
};

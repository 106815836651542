import {
  createContext,
  type Dispatch,
  lazy,
  type LazyExoticComponent,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useState,
  useMemo,
} from "react";
import type { Step } from "react-joyride";
import type Joyride from "react-joyride";

import { useFlag } from "@superweb/flags";

import { useMessage } from "#internal/intl";

type OnboardingState = {
  JoyrideComponent?: LazyExoticComponent<typeof Joyride>;
  isStartModalVisible: boolean;
  setIsStartModalVisible: Dispatch<SetStateAction<boolean>>;
  isFinishModalVisible: boolean;
  setIsFinishModalVisible: Dispatch<SetStateAction<boolean>>;
  isOnboarding: boolean;
  setIsOnboarding: Dispatch<SetStateAction<boolean>>;
  steps: Step[];
  currentStepIndex: number | null;
  setCurrentStepIndex: (value: number | null) => void;
  needToShowOnboarding: boolean;
  setNeedToShowOnboarding: (value: boolean) => void;
  isOnboardingPassed: boolean;
  setIsOnboardingPassed: (value: boolean) => void;
  isFeatureEnabled: boolean;
  setIsFeatureEnabled: (value: boolean) => void;
};

export const OnboardingContext = createContext<OnboardingState>(
  {} as OnboardingState,
);

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const message = useMessage();
  const flag = useFlag();

  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [isOnboardingPassed, setIsOnboardingPassed] = useState(true);
  const [needToShowOnboarding, setNeedToShowOnboarding] = useState(false);

  const [JoyrideComponent, setJoyrideComponent] =
    useState<LazyExoticComponent<typeof Joyride>>();

  const [isStartModalVisible, setIsStartModalVisible] = useState(false);

  const [isFinishModalVisible, setIsFinishModalVisible] = useState(false);

  const [isOnboarding, setIsOnboarding] = useState(false);

  const [steps, setSteps] = useState<Step[]>([]);
  const [currentStepIndex, setCurrentStepIndex] = useState<number | null>(null);

  useEffect(() => {
    setNeedToShowOnboarding(!isOnboardingPassed);
  }, [isOnboardingPassed]);

  useEffect(() => {
    if (isFeatureEnabled && needToShowOnboarding) {
      setIsStartModalVisible(true);

      if (!JoyrideComponent) {
        setJoyrideComponent(lazy(() => import("react-joyride")));
      }
    }
  }, [needToShowOnboarding, JoyrideComponent, flag, isFeatureEnabled]);

  const stepsArray: Array<Step> = useMemo(
    () => [
      {
        target: "#onboarding-leads-table",
        placement: "top-end",
        title: message({
          id: "453d41cb-04a9-43bc-b3d5-a87da3c05f17",
          context: "Onboarding steps. First step title",
          default: "Track new couriers",
        }),
        content: message({
          id: "0ab3735a-b883-4833-8305-70be96061e3e",
          context: "Onboarding steps. First step text",
          default: `Couriers who join by a partner link show up in the table automatically. The status shows which step of the process they're at.`,
        }),
      },
      {
        target: "#onboarding-leads-table-second-part",
        placement: "top-end",
        title: message({
          id: "cbda4c97-0a5c-4c6b-952c-3e769c6f182c",
          context: "Onboarding steps. Second step title",
          default: "Get paid for completed orders",
        }),
        content: message({
          id: "bb253424-ac3e-4fe3-9d18-7da84ae644eb",
          context: "Onboarding steps. Second step text",
          default: `It's simple: we pay you for each registered courier who completes five orders. Five orders is the target action for your rate.`,
        }),
      },
      {
        target: "#onboarding-leads-table-second-part",
        placement: "top-end",
        title: message({
          id: "b0052da1-ee95-4853-ac2a-254ad8f00ad2",
          context: "Onboarding steps. Third step title",
          default: "More orders, higher earnings",
        }),
        content: message({
          id: "2ef588bc-2105-4af9-9c9f-b2499d9b8fe6",
          context: "Onboarding steps. Third step text",
          default: `After the courier delivers five orders, you will earn money for each completed order after that. Limit: 100 orders in the first 20 days.`,
        }),
      },
      {
        target: "#onboarding-leads-partners-link",
        placement: "bottom",
        title: message({
          id: "e6f4b272-4a14-4d88-aaf7-6def0c21fb15",
          context: "Onboarding steps. Fourth step title",
          default: "Invite couriers with the link",
        }),
        content: message({
          id: "c97ea52d-108d-4ff7-834a-810521553e7a",
          context: "Onboarding steps. Fourth step text",
          default: `Copy the link and send it to candidates directly`,
        }),
      },
      {
        target: "#onboarding-leads-lead-check",
        placement: "bottom",
        title: message({
          id: "794133ff-e0ac-4d74-9df0-a5c5c7b170ac",
          context: "Onboarding steps. Fifth step title",
          default: "Check courier eligibility",
        }),
        content: message({
          id: "441f7113-0f5b-46c3-81e7-797a5f71edee",
          context: "Onboarding steps. Fifth step text",
          default: `If a candidate was registered with the service previously, you won't be able to invite them`,
        }),
      },
    ],
    [message],
  );

  useEffect(() => {
    if (!steps.length) {
      setSteps(
        stepsArray.map((step, _, array) => ({
          ...step,
          disableBeacon: true,
          disableCloseOnEsc: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          data: { stepsCount: array.length },
        })),
      );
    }
  }, [steps.length, stepsArray]);

  return (
    <OnboardingContext.Provider
      value={{
        JoyrideComponent,
        isStartModalVisible,
        setIsStartModalVisible,
        isFinishModalVisible,
        setIsFinishModalVisible,
        isOnboarding,
        setIsOnboarding,
        steps,
        currentStepIndex,
        setCurrentStepIndex,
        needToShowOnboarding,
        setNeedToShowOnboarding,
        isOnboardingPassed,
        setIsOnboardingPassed,
        isFeatureEnabled,
        setIsFeatureEnabled,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

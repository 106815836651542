/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, type ChangeEvent } from "react";

import { Button, icons, useUiColors } from "@superweb/ui";
import { cssFns, useCss, keyframes } from "@superweb/css";

export const Attach = ({
  label,
  onChange,
  view,
  accept,
  isFileAttached,
  isLoading,
}: {
  label: string;
  onChange: (value: File) => void;
  view?: "ghost" | "default";
  accept?: string;
  isFileAttached?: boolean;
  isLoading?: boolean;
}) => {
  const uiColors = useUiColors();

  const iconStyles = {
    width: "32px",
    height: "32px",
    gridColumnStart: "icon",
    gridRowStart: "content",
    display: "flex",
    alignItems: "stretch",
    justifyItems: "stretch",
    opacity: isFileAttached ? "1" : "0.5",
    marginInlineStart: "-4px",
    color: isFileAttached ? uiColors.background : uiColors.text,
    ...(isLoading
      ? {
          pointerEvents: "none",
          animationDuration: "2s",
          animationTimingFunction: "linear",
          animationIterationCount: "infinite",
          animationName: keyframes({
            0: {
              transform: "rotateZ(0deg)",
            },
            100: {
              transform: "rotateZ(360deg)",
            },
          }),
        }
      : {}),
  };
  const iconClassname = useCss(iconStyles);

  const ref = useRef<HTMLInputElement>(null);

  const ButtonIcon = () => {
    if (isFileAttached || isLoading) {
      return <icons.ArrowRound className={iconClassname} />;
    }
    return <icons.Plus className={iconClassname} />;
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      onChange(e.target.files[0]);

      if (ref.current?.value) {
        ref.current.value = "";
      }
    }
  };

  return (
    <label
      css={{
        position: "absolute",
        ...cssFns.inset("0"),
        display: "grid",
        justifyItems: "end",
        alignItems: "end",
        cursor: "pointer",
      }}
    >
      <Button
        view={view}
        ariaLabel={label}
        icon={ButtonIcon}
        onPress={() => ref.current?.click()}
      />
      <input
        type="file"
        ref={ref}
        accept={accept}
        css={{ display: "none" }}
        onChange={handleOnChange}
      />
    </label>
  );
};
